import { Injectable } from "@angular/core";
import { CommonService } from "../_common.service";

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  constructor(private commonService: CommonService) {}

  getIPGTransactionList(
    page: number,
    pageSize: number,
    transactionId: string,
    startTime: string,
    endTime: string,
    status: string,
    email: string,
    phoneNo: string
  ) {
    let params = "page=" + page + "&limit=" + pageSize;

    if (transactionId) {
      params += "&payment_id=" + transactionId;
    }
    if (startTime) {
      params += "&start_date=" + startTime;
    }
    if (endTime) {
      params += "&end_date=" + endTime;
    }
    if (status) {
      params += "&status=" + status;
    }
    if (email) {
      params += "&email=" + email;
    }
    if (phoneNo) {
      params += "&phone=" + phoneNo;
    }

    return this.commonService.getDataBase64("transaction/merchant/?" + params);
    // return this.commonService.getDataBase64('transaction/get_all_merchant_transaction_history/?' + params);
  }

  getSingleTransaction(id: number) {
    return this.commonService.getData(
      "transaction/get_by_id_merchant_transaction_history/?id=" + id
    );
  }

  // adminIpgTransactionHistory(page: number, pageSize: number, condition: string) {
  //   return this.commonService.getData('transaction/admin/ipg-transactions-history/?page=' + page + '&limit=' + pageSize + condition);
  // }
  //get qr transaction
  getQrTransactionList(
    page: number,
    pageSize: number,
    transactionId: string,
    startTime: string,
    endTime: string,
    status: string
  ) {
    let params = "page=" + page + "&limit=" + pageSize;

    if (transactionId) {
      params += "&onepay_transaction_id=" + transactionId;
    }
    if (startTime) {
      params += "&start_date=" + startTime;
    }
    if (endTime) {
      params += "&end_date=" + endTime;
    }
    if (status) {
      params += "&status=" + status;
    }

    return this.commonService.getData("ipg/qr-transaction-history/?" + params);
  }
  // refund history
  getRefundHistory(
    page: number,
    pageSize: number,
    ipg_transaction_id: string,
    onepay_transaction_id: string,
    status: string,
    is_partially: boolean,
    id: string
  ) {
    let params = "page=" + page + "&limit=" + pageSize;

    if (ipg_transaction_id) {
      params += "&ipg_transaction_id=" + ipg_transaction_id;
    }
    if (onepay_transaction_id) {
      params += "&onepay_transaction_id=" + onepay_transaction_id;
    }
    if (status) {
      params += "&status=" + status;
    }
    if (is_partially) {
      params += "&is_partially=" + is_partially;
    }
    if (id) {
      params += "&id=" + id;
    }

    return this.commonService.getData("ipg/request-refund/?" + params);
  }

  // customers
  getAllCustomers(
    page: number,
    pageSize: number,
    email: string,
    phoneNumber: string
  ) {
    let params = "page=" + page + "&limit=" + pageSize;

    if (email) {
      params += "&email=" + email;
    }
    if (phoneNumber) {
      params += "&phone_number=" + phoneNumber;
    }
    return this.commonService.getData("ipg/ipg/customer/?" + params);
  }

  getAllViewCustomers(page: number, pageSize: number, id: number) {
    let params =
      "page=" + page + "&limit=" + pageSize + "&ipg_customer_id=" + id;
    return this.commonService.getData("ipg/ipg/customer-deal/?" + params);
  }

  //   getAllViewCustomers(pageNumber, limit,id) {
  //     return this.commonService.getData('ipg/ipg/customer-deal/?page='+ pageNumber + '&limit=' + limit + '&ipg_customer_id=' + id);
  // }

  // get all deal
  getAllViewDeal(pageNumber, limit, id) {
    return this.commonService.getData(
      "ipg/ipg/customer-deal-tokens/?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&deal_id=" +
        id
    );
  }

  // non tranction
  getNonTranasaction(pageNumber, limit, id) {
    return this.commonService.getData(
      "ipg/ipg/customer-deal-non-executed-transactions/?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&deal_id=" +
        id
    );
  }

  //  tranction
  getTranasaction(pageNumber, limit, id) {
    return this.commonService.getData(
      "ipg/ipg/customer-deal-executed-transactions/?page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&deal_id=" +
        id
    );
  }

  postrequestchange(data) {
    return this.commonService.postData(
      "ipg/ipg/request_to_update-customer-token/",
      data
    );
  }

  deleteToken(customer_deal_id) {
    return this.commonService.deleteData(
      "ipg/ipg/delete_token/?customer_deal_id=" + customer_deal_id
    );
  }

  // event
  getEventList(page: number, pageSize: number, event_id: string) {
    let params = "page=" + page + "&limit=" + pageSize;

    if (event_id) {
      params += "&event_id=" + event_id;
    }

    return this.commonService.getData("events/event-history/?" + params);
  }
  // event
  getEvent(page: number, pageSize: number, event_id: string) {
    let params = "page=" + page + "&limit=" + pageSize;

    if (event_id) {
      params += "&event_id=" + event_id;
    }

    return this.commonService.getData("events/event-history/?" + params);
  }
  getEventHistory(
    page: number,
    pageSize: number,
    event_id: string,
    ipg_transaction_id: string,
    customer_email: string,
    status: string,
    start_date: string,
    end_date: string
  ) {
    let params =
      "page=" + page + "&limit=" + pageSize + "&event_id=" + event_id;

    if (ipg_transaction_id) {
      params += "&ipg_transaction_id=" + ipg_transaction_id;
    }
    if (customer_email) {
      params += "&customer_email=" + customer_email;
    }
    if (status) {
      params += "&status=" + status;
    }
    if (start_date) {
      params += "&start_date=" + start_date;
    }
    if (end_date) {
      params += "&end_date=" + end_date;
    }

    return this.commonService.getData(
      "events/event-tarnsaction-history/?" + params
    );
  }
  // uncheck event
  uncheckEventData(data) {
    // return this.commonService.postData('', data);
    return this.commonService.postData(
      "events/event-uncheck-attendance/",
      data
    );
  }
  //resend notification
  getNotification(id: string) {
    return this.commonService.getData(
      "events/resend-notification/?onepay_transaction_id=" + id
    );
  }
  // refund
  refundData(data) {
    // return this.commonService.postData('', data);
    return this.commonService.postData("ipg/request-refund/", data);
  }
  // payout history
  getPayoutHistoyr(
    page: number,
    pageSize: number,
    start_date: string,
    end_date: string
  ) {
    let params = "page=" + page + "&limit=" + pageSize;
    if (start_date) {
      params += "&start_date=" + start_date;
    }
    if (end_date) {
      params += "&end_date=" + end_date;
    }
    return this.commonService.getData("ipg/payout-list/?" + params);
  }
  // batch transaction data
  getBatchTransactionData(batch_id, app_id) {
    return this.commonService.getData(
      "ipg/settlement-data/?batch_id=" + batch_id + "&app_id=" + app_id
    );
  }

  // ipg new
  // get dashbord data
  getIPGDashbordList() {
    return this.commonService.getData("ipg/transaction-count/");
  }
  getIPGTransactionData(
    page: number,
    pageSize: number,
    onepay_transaction_id: string,
    start_date: string,
    end_date: string,
    status: string,
    customer_email: string,
    phone: string,
    is_live: boolean
  ) {
    const isLiveValue = is_live ? 1 : 0;
    let params =
      "page=" + page + "&limit=" + pageSize + "&is_live=" + isLiveValue;

    if (onepay_transaction_id) {
      params += "&onepay_transaction_id=" + onepay_transaction_id;
    }
    if (start_date) {
      params += "&start_date=" + start_date;
    }
    if (end_date) {
      params += "&end_date=" + end_date;
    }
    if (status) {
      params += "&status=" + status;
    }
    if (customer_email) {
      params += "&customer_email=" + customer_email;
    }
    if (phone) {
      params += "&phone=" + phone;
    }

    return this.commonService.getData("ipg/transactions/?" + params);
    // return this.commonService.getDataBase64('transaction/get_all_merchant_transaction_history/?' + params);
  }
  getIPGTransactionDataByID(id) {
    return this.commonService.getDataIpgReport(`api/reports/${id}/details/`);
  }
  tranNote(data) {
    // return this.commonService.postData('', data);
    return this.commonService.postData("ipg/transaction-note/", data);
  }
  deleteNote(data) {
    // return this.commonService.postData('', data);
    return this.commonService.deleteDataBody("ipg/transaction-note/", data);          
  }
  getTransactionRequestData(page: number, pageSize: number) {
    let params =
      "page=" + page + "&limit=" + pageSize + "&request_status=" + 'PENDING';

    return this.commonService.getData("ipg/transactions/requests/?" + params);
    // return this.commonService.getDataBase64('transaction/get_all_merchant_transaction_history/?' + params);
  }
  getTransactionSummaryData(page: number, pageSize: number) {
    let params =
      "page=" + page + "&limit=" + pageSize + "&request_status=" + 'SUCCESS';

    return this.commonService.getData("ipg/transactions/requests/?" + params);
  }
  acceptRefundRequest(transaction_data:any){

    return this.commonService.putData("ipg/transactions-refund-admin/?is_approved="+1,transaction_data);
  }

  declineRefundRequest(transaction_data:any){
    return this.commonService.putData("ipg/transactions-refund-admin/?is_approved="+0,transaction_data);
  }
  // new IPG transaction history
 
  // IPG bew report and filter
  getIpgReport(data,page, page_size) {
    // return this.commonService.postDataIpgReport(`api/reports/view/?page=${page}&page_size=${page_size}`, data);
    return this.commonService.postDataIpgReportEN(`api/reports/view/?page=${page}&page_size=${page_size}`, data);
  }
 
  generateReport(data) {
    return this.commonService.postDataIpgReportXmlTransactionHistory(`api/reports/generate_report/`, data);
    // return this.commonService.downloadReport();
  }
  // generateSettlementReport
  generateSettlementReport(data) {
    return this.commonService.postDataIpgReportXml(`api/reports/settlement_report/`, data);
    // return this.commonService.downloadReport();
  }
  
  getSettlementFilterData(data,page, page_size) {
    return this.commonService.postDataIpgReport(`api/reports/settlement/?page=${page}&page_size=${page_size}`, data);
  }
  // get transaction data view
  getBatchTransaction(id) {
    return this.commonService.getDataIpgReportView(`api/reports/${id}/details/payout/`);
  }
 
}
